import React from 'react';

const Copyright = ({className}) => {
    return (
        <p className={className ? className : ''}>
            © {new Date().getFullYear()} PH Entertainment All Right Reserved
        </p>
    );
};

export default Copyright;
